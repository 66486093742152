$(function() {


    $('.plan-type').addClass('js-plan').css('cursor', 'pointer').on('click', function (){
        window.location = $(this).find('a').attr('href');
    });

    $('.plan-type-load').css('cursor', 'pointer').on('click', function (e){
        if (!$(e.target).is('#planfile')) {
            $("#planfile").trigger('click');
        }
    });

    $('.plan-type a').on('focus', function () {
        $(this).parent().parent().addClass('focused');
    });

    $('.plan-type a, .plan form').on('blur', function () {
        $(this).parent().parent().removeClass('focused');
    });

});
